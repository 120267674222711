body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
}

.nav {
  background-color: #1ac6ff;
  color: black;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: 2rem;
  padding: 0 1rem;
}

.site-title {
  font-size: 2rem;
}

.nav ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: inline;
  display: flex;
  gap: 1rem;
}

.nav a {
  color: inherit;
  text-decoration: none;
}

.logo {
  justify-content: center;
  height: 2rem;
  width: 2rem;
}

.nav li.active {
  background-color: darkolivegreen;
  color: white;
}

.grid-container {
  display: grid;
  border-radius: 0.3rem;
  margin-top: 1rem;
  margin-left: auto;
  margin-right: auto;
  width: 98vw;
  grid-column: 1;
  column-gap: 2rem;
  row-gap: 1rem;
  box-shadow: 0px 0px 5px 5px lightgrey;
}

.grid-content {
  display: grid;
  margin-bottom: 1rem;
  margin-left: 1rem;
  margin-right: auto;
  width: 90vw;
  column-gap: 2rem;
  row-gap: 1rem;
  /* overflow-y: scroll; */
  overflow-x: visible;
}

.form-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
}

.form-box {
  width: 20rem;
  flex-shrink: 0;
  display: grid;
  justify-content: center;
  border-radius: 0.3rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
  grid-gap: 0.5rem;
  border: 1rem;
  box-shadow: 0px 0px 5px 5px lightgrey;
  height: 100%;
  min-height: 0;
}

.form-item {
  width: 18rem;
  margin-bottom: 1rem;
}

.graph-container {
  /* background-color: aqua; */
  width: 80vw;
  height: 100%;
}

.main-card {
  background-color: var(--surface-card);
  width: 100%;
  height: 100%;
  padding: 1.5rem;
  border-radius: 12px;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05),
    0px 1px 4px rgba(0, 0, 0, 0.08) !important;
}
